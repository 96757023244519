<template>
  <div>
    <div class="d-flex w-100 flex-column bs_engaging_bg">
      <div class="container bs_engaging_container">
        <div class="bs_engaging_titles">
          <div class="bs_engaging_title">Our Contents Engaging</div>
          <div ref="e_CountBox" id="e_Count" class="bs_engaging_amount">{{ formatted_e_Count }}</div>
        </div>

        <div class="bs_engaging_details">
          <div class="bs_engaging_detailBox">
            <div class="bs_engaging_detailTitle">Since</div>
            <div class="bs_engaging_detailContext">2021</div>
          </div>
          <div class="bs_engaging_detailBox">
            <div class="bs_engaging_detailTitle">Assets Sold</div>
            <div class="bs_engaging_detailContext">2M+</div>
          </div>
          <div class="bs_engaging_detailBox">
            <div class="bs_engaging_detailTitle">Community</div>
            <div class="bs_engaging_detailContext">510K+</div>
          </div>
        </div>

        <div class="bs_engaging_asof">
          <div>{{ $t('business.asof') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Engaging',
  data(){
    return{
      e_count: 0,
      e_num: 32174024,
      e_counting: null,
      e_hasExecuted: false,
    }
  },
  computed: {
    formatted_e_Count() {
      return new Intl.NumberFormat().format(this.e_count);
    },
  },
  mounted() {
    this.observe_e_CountBox();
  },
  beforeUnmount() {
    clearInterval(this.e_counting);
  },
  methods: {
    start_e_Counting() {
      this.e_counting = setInterval(() => {
        if (this.e_count >= this.e_num) {
          this.e_count = this.e_num;
          clearInterval(this.e_counting);
        } else {
          this.e_count += 1234567;
        }
      }, 20);
    },
    observe_e_CountBox() {
      const e_observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !this.e_hasExecuted) {
            this.e_hasExecuted = true;
            this.on_e_CountVisible();
          }
        });
      });

      e_observer.observe(this.$refs.e_CountBox);
    },
    on_e_CountVisible() {
      setTimeout(() => {
        this.start_e_Counting();
      }, 100);
    },
  },
}
</script>

<style>
.bs_engaging_bg {
  background-color: #87ea5c;
  background-image: url("../../assets/image/pattern/pattern1_w.png");
  padding-top: 104px;
  padding-bottom: 88px;
}
.bs_engaging_container {
  flex-direction: column;
  padding: 0;
}
.bs_engaging_titles {
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.bs_engaging_title {
  width: 160px;
  font-size: 25px;
  font-weight: 500;
  margin-top: 24px;
}
.bs_engaging_amount {
  font-size: 130px;
  font-weight: 700;
}
.bs_engaging_details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 72px;
}
.bs_engaging_detailBox {
  width: 31%;
  height: 288px;
  border-radius: 50px;
  background-color: white;
}
.bs_engaging_detailTitle {
  font-size: 25px;
  font-weight: 500;
  margin-top: 35px;
}
.bs_engaging_detailContext {
  font-size: 70px;
  font-weight: 700;
  margin-top: 56px;
}
.bs_engaging_asof {
  font-size: 20px;
  font-weight: 500;
  text-align: right;
  margin-top: 32px;
}
@media screen and (max-width: 1400px) {
  .bs_engaging_detailBox {
    height: 240px;
  }
  .bs_engaging_detailContext {
    margin-top: 40px;
  }
}
@media screen and (max-width: 1200px) {
  .bs_engaging_titles {
    flex-direction: column;
  }
  .bs_engaging_title {
    width: 100%;
    margin-top: unset;
  }
  .bs_engaging_amount {
    font-size: 100px;
    font-weight: 700;
    margin-top: 32px;
  }
  .bs_engaging_details {
    margin-top: 48px;
  }
  .bs_engaging_detailBox {
    height: 200px;
    border-radius: 25px;
  }
  .bs_engaging_detailTitle {
    margin-top: 32px;
  }
  .bs_engaging_detailContext {
    margin-top: 24px;
  }
  .bs_engaging_asof {
    margin-top: 24px;
  }
}
@media screen and (max-width: 992px) {
  .bs_engaging_bg {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .bs_engaging_detailBox {
    height: 150px;
  }
  .bs_engaging_detailTitle {
    font-size: 25px;
    margin-top: 20px;
  }
  .bs_engaging_detailContext {
    font-size: 45px;
  }
}
@media screen and (max-width: 768px) {
  .bs_engaging_bg {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .bs_engaging_detailBox {
    height: 120px;
  }
  .bs_engaging_detailTitle {
    font-size: 20px;
    margin-top: 20px;
  }
  .bs_engaging_detailContext {
    font-size: 36px;
    margin-top: 16px;
  }
}
</style>