<template>
  <div class="cover-container d-flex w-100 vh-100 p-3 mx-auto flex-column titleSection_BG" :class="place">
        <div class="titleSection_mb"></div>
        <main class="px-3">
          <p class="titleSection_title text-white">{{ getTitleA }}</p>
          <p class="titleSection_title text-white">{{ getTitleB }}</p>
          <p class="titleSection_description text-white">{{ getDescription }}</p>
        </main>
        <div class="mt-auto text-white-50">
        </div>
      </div>
</template>

<script>
import { useRoute } from 'vue-router'
export default {
  name: 'TitleSection',
  data(){
    return{
      place: useRoute().path.split('/')[1]
    }
  },
  computed: {
    getTitleA() {
      const pathMap = {
        '/about': 'about.titleSection.title.titleA',
        '/business': 'business.titleSection.title.titleA',
        '/': 'main.titleSection.title.titleA'
      }
      return this.$t(pathMap[this.$route.path] || 'main.titleSection.title')
    },
    getTitleB() {
      const pathMap = {
        '/about': 'about.titleSection.title.titleB',
        '/business': 'business.titleSection.title.titleB',
        '/': 'main.titleSection.title.titleB'
      }
      return this.$t(pathMap[this.$route.path] || 'main.titleSection.title')
    },
    getDescription() {
      const pathMap = {
        '/about': 'about.titleSection.description',
        '/business': 'business.titleSection.description',
        '/': 'main.titleSection.description'
      }
      return this.$t(pathMap[this.$route.path] || 'main.titleSection.description')
    }
  }
}
</script>

<style>
.titleSection_BG {
  background-image: url("../../assets/image/titleSection/main.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  max-height: 930px;
  min-height: 480px;
}
.titleSection_title {
  font-size: 70px;
  font-weight: 900;
  margin-bottom: 12px;
}
.titleSection_description {
  font-size: 25px;
  font-weight: 600;
  margin-top: 32px;
}
.titleSection_mb {
  margin-top: 10%;
}
.about {
  background-image: url("../../assets/image/titleSection/about.jpg") !important;
}
.business {
  background-image: url("../../assets/image/titleSection/business.jpg") !important;
}
@media screen and (max-width: 1200px) {
  .titleSection_title {
    font-size: 60px;
  }
  .titleSection_mb {
    margin-top: 20%;
  }
}
@media screen and (max-width: 700px) {
  .titleSection_title {
    font-size: 50px;
  }
  .titleSection_description {
    font-size: 20px;
  }
}
@media screen and (max-width: 600px) {
  .titleSection_title {
    font-size: 40px;
  }
  .titleSection_mb {
    margin-top: 30%;
  }
}
@media screen and (max-width: 430px) {
  .titleSection_mb {
    margin-top: 40%;
  }
}
</style>