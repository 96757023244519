<template>
    <div>
    <div class="RTLslideWrap">
      <ul class="RTLimgSlide RTLoriginal">
        <li v-for="(item, index) in RTLoriginalSlides" :key="index" class="RTLlistPosition">
          <SlideCard :SlideCardImg="item.image" :SlideCardSide="item.side"/>
        </li>
      </ul>
      <ul class="RTLimgSlide RTLclone">
        <li v-for="(item, index) in RTLclonedSlides" :key="index" class="RTLlistPosition">
          <SlideCard :SlideCardImg="item.image" :SlideCardSide="item.side"/>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import SlideCard from './SlideCard.vue';

export default {
  name: 'RTLslide',
  components: {
    SlideCard
  },
  data() {
    const RTLoriginalSlides = ref([
      {
        id: 1,
        side: 'slide_top',
        image: require('../../assets/image/making/making_1.jpg')
      },
      {
        id: 2,
        side: 'slide_bottom',
        image: require('../../assets/image/making/making_2.jpg')
      },
      {
        id: 3,
        side: 'slide_top',
        image: require('../../assets/image/making/making_3.jpg')
      },
        {
        id: 4,
        side: 'slide_bottom',
        image: require('../../assets/image/making/making_4.jpg')
      },
      {
        id: 5,
        side: 'slide_top',
        image: require('../../assets/image/making/making_5.jpg')
      },
      {
        id: 6,
        side: 'slide_bottom',
        image: require('../../assets/image/making/making_6.jpg')
      }   
    ]);
    const RTLclonedSlides = ref([...RTLoriginalSlides.value]);

    onMounted(() => {
      // 복제본 추가
      document.querySelector('.RTLslideWrap').appendChild(document.querySelector('.RTLclone'));

      // 원본, 복제본 위치 지정
      document.querySelector('.RTLimgSlide').style.width = document.querySelector('.RTLimgSlide').offsetWidth + 'px';
    });

    return {
      RTLoriginalSlides,
      RTLclonedSlides
    };
  }
};
</script>

<style>
/* reset */
section { width: 100%; }
li { display: inline-block; list-style: none; }

/* 슬라이드 */
.RTLslideWrap {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  height: 440px;
  overflow: hidden;
}
.RTLslideWrap .RTLimgSlide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0;
}
.RTLslideWrap .RTLimgSlide .RTLlistPosition {
  position: relative;
}
.RTLslideWrap .RTLimgSlide.RTLoriginal {
  animation: 40s linear 0s infinite normal forwards running RTLslide01;
}
.RTLslideWrap .RTLimgSlide.RTLclone {
  animation: 40s linear 0s infinite normal none running RTLslide02;
}
.RTLslideWrap .RTLimgSlide li {
  width: 400px;
  height: 416px;
  line-height: 416px;
  margin-right: 20px;
  text-align: center;
}

/* 애니메이션 */
@keyframes RTLslide01 {
  0% { transform: translateX(0); }
  50% { transform: translateX(-100%); }
  50.01% { transform: translateX(100%); }
  100% { transform: translateX(0); }
}
@keyframes RTLslide02 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-200%); }
}
</style>