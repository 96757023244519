<template>
  <div>
    <div class="d-flex w-100 flex-column">
      <div class="container bs_collabo_title">
        <div class="bs_title_area">
          <sectionTitle :msg="$t('business.brandSection.title')" :margin="30" />
        </div>
        <div>
          <p class="bs_is_desc">{{ $t('business.brandSection.description') }}</p>
        </div>
      </div>
    </div>

    <div>
      <ul class="bs_CardList">
        <li v-for="(a, i) in CollaboCardList" :key="i" class="bs_brand_item">
          <CollaborationCard :CCtitle="a.title" :CCsubtitle="a.subtitle" :CCdescription_code="a.description" :CClogo="a.logo" :CCimage="a.image" />
        </li>
      </ul>
    </div>

    <div class="d-flex w-100 flex-column">
      <div class="container bs_collabo_btn">
        <button @click="handleClick('brandPrev')" class="btn-circle" :class="{'bs_btnDisabled' : brandPrevDisabled}" type="button">
          <img src="../../assets/icon/arrowL.svg" class="btn-icon" :class="{'bs_iconDisabled' : brandPrevDisabled}" alt="Prev" />
        </button>
        <button @click="handleClick('brandNext')" class="btn-circle btn-next" :class="{'bs_btnDisabled' : brandNextDisabled}" type="button">
          <img src="../../assets/icon/arrowR.svg" class="btn-icon" :class="{'bs_iconDisabled' : brandNextDisabled}"  alt="Next" />
        </button>
      </div>
    </div>
  </div>


  

  <!-- <button type="button" class="btn btn-primary" @click="more">더보기</button> -->
</template>

<script>
import sectionTitle from '../common/sectionTitle.vue';
import CollaborationCard from './CollaborationCard.vue';

export default {
  name: 'BrandCol',
  data(){
    return{
      CollaboCardList: [
        {
          id: 1,
          title: 'GS Retail',
          subtitle: '[ROBLOX X GS Retail X LIFFY]',
          description: 'business.brandSection.colA',
          logo: require('../../assets/image/brandLogo_s/logo_gs.png'),
          image: require('../../assets/image/brandCollabo/col_gs.jpg')
        },
        {
          id: 2,
          title: 'Hyundai',
          subtitle: '[HYUNDAI X LIFFY]',
          description: 'business.brandSection.colB',
          logo: require('../../assets/image/brandLogo_s/logo_hyundai.png'),
          image: require('../../assets/image/brandCollabo/col_hyundai.jpg')
        },
        {
          id: 3,
          title: 'Slickdeals',
          subtitle: '[Slickdeals X LIFFY]',
          description: 'business.brandSection.colC',
          logo: require('../../assets/image/brandLogo_s/logo_slickdeals.png'),
          image: require('../../assets/image/brandCollabo/col_slickdeals.jpg')
        },
        {
          id: 4,
          title: 'Apple Korea',
          subtitle: '[ROBLOX X APPLE X LIFFY]',
          description: 'business.brandSection.colD',
          logo: require('../../assets/image/brandLogo_s/logo_appleKorea.png'),
          image: require('../../assets/image/brandCollabo/col_appleKorea.jpg')
        },
        {
          id: 5,
          title: 'Studdy',
          subtitle: '[Studdy X LIFFY]',
          description: 'business.brandSection.colE',
          logo: require('../../assets/image/brandLogo_s/logo_studdy.png'),
          image: require('../../assets/image/brandCollabo/col_studdy.jpg')
        }
      ],
      brandPrevDisabled: true,
      brandNextDisabled: false,
      brandScreenCnt: 0
    }
  },
  components: {
    sectionTitle,
    CollaborationCard
  },
  methods: {
    handleClick(direction) {
      const bs_CardList = document.querySelector(".bs_CardList")
      const bs_brand_item = document.querySelector(".bs_brand_item");
      const brandItemWidth = bs_brand_item.offsetWidth;
      if(direction === "brandPrev" && !this.brandPrevDisabled) {
        bs_CardList.scrollBy({ left: -brandItemWidth, behavior: "smooth" });
      } else if(direction === "brandNext" && !this.brandNextDisabled) {
        bs_CardList.scrollBy({ left: brandItemWidth, behavior: "smooth" });
      }
    },
  },
  mounted() {
    const bs_CardList = document.querySelector(".bs_CardList")
    const bs_brand_item = document.querySelector(".bs_brand_item");
    bs_CardList.addEventListener('scroll', () => {
      this.brandScreenCnt = (window.innerWidth > 1400 && 2) || 3;
      this.brandPrevDisabled = bs_CardList.scrollLeft === 0;
      this.brandNextDisabled = bs_CardList.scrollLeft >= (bs_brand_item.offsetWidth + 48) * this.brandScreenCnt;
    })
  },
}

</script>

<style>
.bs_title_area {
  display: flex;
  justify-content: space-between;
}
.bs_collabo_title {
  flex-direction: column;
  text-align: left;
  padding-left: 0px;
}
.bs_brand_item {
  flex-shrink: 0;
  width: 616px;
  height: 731px;
  border-radius: 50px;
  scroll-snap-align: start;
}
.bs_CardList {
  height: 751px;
  display: flex;
  gap: 48px;
  padding: 0px calc((100% - 1320px)/2);
  scroll-padding: 0px calc((100% - 1320px)/2); 
  align-items: center;
  /* overflow-x: scroll; */
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: hidden;
  margin-bottom: 48px;
}
.bs_CardList::-webkit-scrollbar {
  display: none;
}
.bs_collabo_btn {
  justify-content: right;
}
.bs_btnDisabled {
  background-color: #ecefeb !important;
  cursor: not-allowed;
}
.bs_iconDisabled {
  filter: invert(78%) sepia(4%) saturate(231%) hue-rotate(62deg) brightness(90%) contrast(87%);
}
@media screen and (max-width: 1400px) {
  .bs_CardList {
    padding: 0px calc((100% - 1140px)/2);
    scroll-padding: 0px calc((100% - 1140px)/2); 
  }
}
@media screen and (max-width: 1200px) {
  .bs_CardList {
    padding: 0px calc((100% - 960px)/2);
    scroll-padding: 0px calc((100% - 960px)/2); 
  }
}
@media screen and (max-width: 992px) {
  .bs_CardList {
    padding: 0px calc((100% - 720px)/2);
    scroll-padding: 0px calc((100% - 720px)/2); 
  }
}
@media screen and (max-width: 768px) {
  .bs_CardList {
    padding: 0px calc((100% - 540px)/2);
    scroll-padding: 0px calc((100% - 540px)/2); 
  }
}















</style>