<template>
    <div class="slideDiv" :style="{ backgroundImage: `url(${SlideCardImg})`, top: TopPosition, bottom: BottomPosition }">
        
    </div>
</template>

<script>

export default {
  name: 'slideCard',
  data(){
    return{
      
    }
  },
  props: {
    SlideCardImg: {
        type: String,
        require: true
    },
    SlideCardSide: {
        type: String,
        require: true,
        validator: (value) => ['slide_top', 'slide_bottom'].includes(value)
    }
  },
  computed: {
    TopPosition() {
      return this.SlideCardSide === 'slide_top' ? '0' : 'auto'
    },
    BottomPosition() {
      return this.SlideCardSide === 'slide_bottom' ? '0' : 'auto'
    }
  }
}
</script>

<style>
.slideDiv {
    height: 360px;
    width: 100%;
    position: absolute;
    border-radius: 50px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>