<template>
  <a :href="socialLink" class="bs-social-card-div" target="_blank">
    <div class="bs-social-card-type">
        <div class="bs-social-card-img" :style="{ backgroundImage: `url(${socialLogo})` }"></div>
        <div class="bs-social-card-title">{{socialTitle}}</div>
    </div>
    
    <div class="bs-social-card-btn">
        <div class="bs-social-card-btnDesc">View &gt;</div>
    </div>
  </a>
</template>

<script>
export default {
    name: 'SocialCard',
    data(){
        return{
            
        }
    },
    props: {
        socialTitle: {
            type: String,
            require: true
        },
        socialLogo: {
            type: String,
            require: true
        },
        socialLink: {
            type: String,
            require: true
        }
    }
}
</script>

<style>
.bs-social-card-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    padding: 56px 40px 64px 40px;
    background-color: #ecefeb;
    transition: 0.3s;
    text-decoration: none;
    color: inherit;
}
.bs-social-card-type {
    text-align: -webkit-center;
}
.bs-social-card-img {
    width: 200px;
    height: 200px;
    border-radius: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bs-social-card-title {
    font-size: 30px;
    font-weight: 600;
    margin-top: 24px;
}
.bs-social-card-btn {
    margin-top: 56px;
}
.bs-social-card-btnDesc {
    font-size: 30px;
    font-weight: 700;
}
.bs-social-card-logo {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    border: thin solid rgba(0, 0, 0, 0.15);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: inline-block;
    vertical-align: middle;
}
.bs-social-card-div:hover {
    background-color: #87ea5c;
}
</style>