<template>
    <a :href="link" class="scContent main_os_item" target="_blank" :style="{ backgroundImage: `url(${bgImg})` }">
    <!-- <a :href="link" class="scContent main_os_item" target="_blank"> -->
        <div class="scGradient" />
        <div class="scDiv">
            <p class="scCategory">{{category}}</p>
            <p class="scTitle">
                {{os_title}}
                <img :src="arrowSVG" alt="Section Title"  class="scIcon"/>
            </p>
        </div>
    </a>
</template>

<script>

export default {
    name: 'serviceCard',
    data(){
        return{
            arrowSVG: require('../../assets/icon/arrowS.svg'),
        }
    },
    props: {
        os_titleCode: {
            type: String,
            require: true
        },
        category: {
            type: String,
            require: true
        },
        link: {
            type: String,
            require: true
        },
        bgImg: {
            type: String,
            require: true
        }
    },
    computed: {
        os_title() {
            return this.$t(this.os_titleCode);  // 키를 사용하여 번역된 문자열 반환
        }
    }
}
</script>

<style>

.scContent {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;

  font-family: sans-serif;
  font-size: 64px;
  font-weight: bold;
}

.scGradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
}

.main_os_item:hover .scGradient {
    background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.9) 100%);
}

.scIcon {
    filter: invert(100%) sepia(1%) saturate(2%) hue-rotate(177deg) brightness(107%) contrast(101%);
    width: 24px;
    height: 24px;   
    margin-left: 8px;
}

.scDiv {
    position: absolute;
    bottom: 80px;
    left: 64px;
}

.scCategory {
    color: #9ce44a;
    font-size: 30px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 0px;
}

.scTitle {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 0px;
    
}
</style>