<template>
    <div class="stRestrictWidth" :style="{ marginBottom: margin + 'px' }">
        <p class="stParagraph">
            <img :src="flowerSVG" alt="Section Title" :class="stIconColor"/>
            {{ msg }}
        </p>
        <p v-if="link">
          <a :href="link" target="_blank" class="stAllBtn">See All &gt;</a>
        </p>
    </div>
</template>

<script>

export default {
  name: 'sectionTitle',
  data(){
    return{
      flowerSVG: require('../../assets/icon/flower.svg'),
    }
  },
  props: {
    msg: {
      type: String,
      require: true
    },
    margin: {
      type: Number,
      require: true
    },
    iconBlack: {
      type: Boolean,
      require: true
    },
    link: {
      type: String,
      require: true
    }
  },
  computed: {
    stIconColor() {
      return this.iconBlack ? 'stBlackIcon' : 'stIcon';
    }
  }
}
</script>

<style>
    .stRestrictWidth {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .stParagraph {
        text-align: left;
        font-size: 35px;
        font-weight: 700;
        margin-bottom: 0px;
        padding-left: 0px;
    }
    .stBlackIcon {
      filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(131deg) brightness(98%) contrast(108%);
      width: 30px;
      height: 30px;
      display: inline-block;
      vertical-align: middle;
    }
    .stIcon {
        filter: invert(83%) sepia(93%) saturate(381%) hue-rotate(38deg) brightness(98%) contrast(88%);
        width: 30px;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
    }
    .stAllBtn {
      color: #38b203;
      font-size: 20px;
      font-weight: 600;
      text-decoration: none;
    }
    .stAllBtn:hover {
      color: #87ea5c;
    }
</style>