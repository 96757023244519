<template>
    <TitleSection />
    <div class="mainBG">
        <MainDescription />
        <OurService class="mainSection"/>
    </div>
    
    <GetInTouch />
</template>

<script>
import TitleSection from '../common/TitleSection.vue';
import OurService from '../main/OurService.vue';
import MainDescription from '../main/MainDescription.vue';
import GetInTouch from '../common/GetInTouch.vue';

export default {
    data(){
        return{
            
        }
    },
    components: {
        TitleSection,
        MainDescription,
        OurService,
        GetInTouch
    }
}
</script>

<style>
    .mainBG {
        background-image: url("../../assets/image/pattern/pattern1.png");
        padding-top: 220px;
        padding-bottom: 150px;
    }
    .mainDesc {
        margin-top: 220px;
    }

    .mainSection {
        margin-top: 150px;
    }
</style>