<template>
  <div>
    <div class="d-flex w-100 flex-column bs_trusted_bg">
      <div class="container bs_engaging_container">
        <div class="bs_trusted_titleArea">
          <img :src="flowerSVG" class="bs_trusted_icon"/>
          <img :src="flowerSVG" class="bs_trusted_icon bs_trusted_tilt bs_trusted_mo"/>
          <img :src="flowerSVG" class="bs_trusted_icon bs_trusted_tab"/>
          <p class="bs_trusted_title">Trusted by</p>
          <img :src="flowerSVG" class="bs_trusted_icon bs_trusted_tab"/>
          <img :src="flowerSVG" class="bs_trusted_icon bs_trusted_tilt bs_trusted_mo"/>
          <img :src="flowerSVG" class="bs_trusted_icon"/>
        </div>
      
        

        <div class="bs_trusted_area">
          <ul class="bs_trusted_list">
            <li v-for="(a, i) in TrustedBrandList" :key="i" class="bs_trusted_brands" :style="{ backgroundImage: `url(${a.logo})` }"></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrustedBy',
  data(){
    return{
      flowerSVG: require('../../assets/icon/flower.svg'),
      TrustedBrandList: [
        {
          id: 1,
          logo: require('../../assets/image/brandLogo_l/logo_apple.png')
        },
        {
          id: 2,
          logo: require('../../assets/image/brandLogo_l/logo_gs.png')
        },
        {
          id: 3,
          logo: require('../../assets/image/brandLogo_l/logo_roblox.png')
        },
        {
          id: 4,
          logo: require('../../assets/image/brandLogo_l/logo_hyundai.png')
        },
        {
          id: 5,
          logo: require('../../assets/image/brandLogo_l/logo_slickdeals.png')
        },
        {
          id: 6,
          logo: require('../../assets/image/brandLogo_l/logo_studdy.png')
        }
      ]
    }
  }
}
</script>

<style>
.bs_trusted_bg {
  background-color: #083400;
  padding-top: 64px;
  padding-bottom: 48px;
}
.bs_trusted_titleArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.bs_trusted_title {
  color: #87ea5c;
  font-size: 35px;
  font-weight: 700;
  width: 360px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 40px;
}

.bs_trusted_icon {
  filter: invert(72%) sepia(38%) saturate(626%) hue-rotate(56deg) brightness(105%) contrast(93%);
  width: 30px;
  height: 30px;
  margin: auto 20px;
}
.bs_trusted_tilt {
    transform: rotate(45deg);
}
.bs_trusted_area {
  margin-top: 56px;
}
.bs_trusted_list {
  width: 100%;
}
.bs_trusted_brands {
  width: calc((100% - 30px) / 3);
  height: 56px;
  margin: 8px 5px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: 0.5;
}
@media (max-width: 992px) {
  .bs_trusted_tab {
    display: none;
  }
}
@media (max-width: 768px) {
  .bs_trusted_mo {
    display: none;
  }
}
</style>