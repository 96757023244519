<template>
  <div>
    <div class="d-flex w-100 flex-column">
      <div class="container os_service_title">
        <div class="ms_title_area">
          <sectionTitle :msg="$t('main.serviceSection.title')" :margin="40" />
        </div>
      </div>
    </div>

    <div>
      <ul class="os_CardList">
        <li v-for="(a,i) in OurServiceCardList" :key="i" class="main_os_item">
          <ServiceCardVue :os_titleCode="a.title" :category="a.category" :link="a.hyperlink" :bgImg="a.image" />
        </li>
      </ul>
    </div>

    <div class="d-flex w-100 flex-column">
      <div class="container ms_btn">
        <button @click="handleClick('previous')" class="btn-circle" :class="{'ms_btnDisabled' : servicePreviousDisabled}"  type="button">
          <img src="../../assets/icon/arrowL.svg" class="btn-icon" :class="{'ms_iconDisabled' : servicePreviousDisabled}" alt="Prev" />
        </button>
        <button @click="handleClick('next')" class="btn-circle btn-next" :class="{'ms_btnDisabled' : serviceNextDisabled}"  type="button">
          <img src="../../assets/icon/arrowR.svg" class="btn-icon" :class="{'ms_iconDisabled' : serviceNextDisabled}" alt="Next" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import sectionTitle from '../common/sectionTitle.vue'
import ServiceCardVue from '../common/ServiceCard.vue'

export default {
  name: 'BreakCarousel',
  data(){
    return{
      OurServiceCardList: [
        {
          id: 1,
          title: 'main.serviceSection.service.serviceA',
          category: 'In Roblox',
          hyperlink: 'https://bit.ly/3O4r28U',
          image: require('../../assets/image/service/OurService_Card1.jpg')
        },
        {
          id: 2,
          title: 'main.serviceSection.service.serviceB',
          category: 'In Roblox',
          hyperlink: 'https://bit.ly/3O2dxpu',
          image: require('../../assets/image/service/OurService_Card2.jpg')
        },
        {
          id: 3,
          title: 'main.serviceSection.service.serviceC',
          category: 'In X',
          hyperlink: 'https://x.com/lily_liffy',
          image: require('../../assets/image/service/OurService_Card3.jpg')
        }
      ],
      servicePreviousDisabled: true,
      serviceNextDisabled: false,
      serviceScreenCnt: 0
    }
  },
  components: {
    sectionTitle,
    ServiceCardVue
  },
  methods: {
    handleClick(direction) {
      const os_CardList = document.querySelector(".os_CardList")
      const main_os_item = document.querySelector(".main_os_item");
      const main_os_itemWidth = main_os_item.offsetWidth;
      if(direction === "previous" && !this.servicePreviousDisabled) {
        os_CardList.scrollBy({ left: -main_os_itemWidth, behavior: "smooth" });
      } 
      else if(direction === "next" && !this.serviceNextDisabled) {
        os_CardList.scrollBy({ left: main_os_itemWidth, behavior: "smooth" });
      }
    }
  },
  mounted() {
    const os_CardList = document.querySelector(".os_CardList")
    const main_os_item = document.querySelector(".main_os_item");
    os_CardList.addEventListener('scroll', () => {
      console.log(window.innerWidth)
      this.serviceScreenCnt = (window.innerWidth > 1400 && 0.1) || 1;
      this.servicePreviousDisabled = os_CardList.scrollLeft === 0;
      this.serviceNextDisabled = os_CardList.scrollLeft >= (main_os_item.offsetWidth + 48) * this.serviceScreenCnt;
    })
  }
}

</script>

<style>
.os_service_title {
  padding-left: 0px;
}
.ms_title_area {
  display: flex;
  justify-content: space-between;
}
.main_os_item {
  flex-shrink: 0;
  width: 616px;
  height: 731px;
  border-radius: 50px;
  scroll-snap-align: start;
}
.os_CardList {
  height: 751px;
  display: flex;
  gap: 48px;
  padding: 0px calc((100% - 1320px)/2);
  scroll-padding: 0px calc((100% - 1320px)/2); 
  align-items: center;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 48px;
}
.os_CardList::-webkit-scrollbar {
  display: none;
}
.ms_btn {
  justify-content: right;
}
.btn-circle {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #87ea5c;
  display: inline-block;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.btn-circle::before {
  background-size: 24px 24px;
  display: inline-block;
}
.btn-circle:hover {
  background-color: #6cff2c;
}
.btn-next {
  margin-left: 24px;
}
.ms_btnDisabled {
  background-color: #ecefeb !important;
  cursor: not-allowed;
}
.ms_iconDisabled {
  filter: invert(78%) sepia(4%) saturate(231%) hue-rotate(62deg) brightness(90%) contrast(87%);
}
.btn-icon {
  width: 24px;
  height: 24px;
  fill: #fff;
}



@media screen and (max-width: 1400px) {
  .os_CardList {
    padding: 0px calc((100% - 1140px)/2);
    scroll-padding: 0px calc((100% - 1140px)/2); 
  }
}
@media screen and (max-width: 1200px) {
  .os_CardList {
    padding: 0px calc((100% - 960px)/2);
    scroll-padding: 0px calc((100% - 960px)/2); 
  }
}
@media screen and (max-width: 992px) {
  .os_CardList {
    padding: 0px calc((100% - 720px)/2);
    scroll-padding: 0px calc((100% - 720px)/2); 
  }
}
@media screen and (max-width: 768px) {
  .os_CardList {
    padding: 0px calc((100% - 540px)/2);
    scroll-padding: 0px calc((100% - 540px)/2); 
  }
}
</style>