<template>
  <div>
    <div class="d-flex w-100 flex-column contact_bg">
      <div class="container contact_container">
        <div class="contact_bgImage">
          <div class="contact_mainImage"></div>
        </div>

        <div class="contact_inputArea">
          <div class="contact_input_title"><p>Contact us</p></div>
          <form @submit.prevent="submitForm">
            <div class="contact_input_group">
              <div><label for="author">Name</label></div>
              <input type="text" id="author" v-model="author" required />
            </div>
            <div class="contact_input_group">
              <div><label for="email">Email</label></div>
              <input type="email" id="email" v-model="email" required />
            </div>
            <div class="contact_input_group">
              <div><label for="message">Message</label></div>
              <textarea id="message" v-model="message" required></textarea>
            </div>
            <div class="g-recaptcha" :data-sitekey="siteKey"></div>
            <div class="contact_input_submitDiv">
              <button class="contact_input_submit" type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'contact',
  data(){
      return{
        message: '',
        author: '',
        email: '',
        siteKey: 'YOUR_SITE_KEY', // 여기 사이트 키 입력
      }
  },
  methods: {
    async submitForm() {
      // const recaptchaResponse = grecaptcha.getResponse(); //reCAPTCHA
      // if (!recaptchaResponse) {
      //   alert('reCAPTCHA를 완료해주세요.');
      //   return;
      // }
      // http://localhost:3000/send-email
      // http://:${process.env.PORT}/send-email
      // http://liffy.net:8080/send-email
      try {
        const serverUrl = `http://liffy.net:8080/contact/send-email`;  // 테스트를 위한 수정 240902
        const response = await fetch(serverUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
             message: this.message,
             author: this.author,
             email: this.email,
            //  recaptchaResponse //reCAPTCHA
          })
        });
        if (response.ok) {
          alert('요청이 성공적으로 전송되었습니다.');
          this.message = '';
          this.author = '';
          this.email = '';
        } else {
          alert('요청 전송에 실패했습니다.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('오류가 발생했습니다.');
      }
    }
  }
}
</script>

<style>
.contact_bg {
  text-align: left;
  background-color: #87ea5c;
  background-image: url("../../assets/image/pattern/pattern1_w.png");
  padding-top: 96px;
  padding-bottom: 64px;
}
.contact_container {
  flex-direction: row;
}
.contact_mainImage {
  width: 560px;
  height: 720px;
  border-radius: 20px;
  border : solid 6px white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url('../../assets/image/service/OurService_Card1.jpg');
}
.contact_inputArea {
  width: calc(100% - 624px);
  margin-left: 64px;
}
.contact_input_title {
  font-size: 70px;
  font-weight: 900;
  margin-bottom: 24px;
}
.contact_input_group {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px;
}
.contact_input_group div {
  margin-bottom: 12px;
}
.contact_input_group input {
  width: 100%;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  padding: 16px 12px;
}
.contact_input_group textarea {
  width: 100%;
  height: 160px;
  font-size: 16px;
  border: none;
  border-radius: 15px;
  padding: 12px;
  resize: none;
}
.contact_input_group textarea::-webkit-scrollbar {
  width: 24px;
}
.contact_input_group textarea::-webkit-scrollbar-thumb {
  background-color: rgba(8,52,0,1);
  border-radius: 50px; 
  border: 8px solid rgba(255,255,255,1);
}
.g-recaptcha {
  margin-bottom: 28px;
  text-align: -webkit-right;
}
.contact_input_submitDiv {
  text-align: -webkit-right;
}
.contact_input_submit {
  width: 240px;
  height: 48px;
  background-color: #083400;
  border-radius: 50px;
  border: none;
  font-size: 20px;
  font-weight: 700;
  color: white;
  right: 0;
  bottom: 0;
  transition: 0.3s;
}
.contact_input_submit:hover {
  background-color: #0c5000;
  color: #87ea5c;
}

@media screen and (max-width: 1200px) {
  .contact_container {
    flex-direction: column-reverse;
  }
  .contact_mainImage {
    width: 100%;
    height: 640px;
    margin-top: 88px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .contact_inputArea {
    width: 100%;
    margin-left: 0px;
  }
}
</style>