<template>
  <div class="bs-col-card-div">
    <div class="bs-col-card-img" :style="{ backgroundImage: `url(${CCimage})` }"></div>
    <div class="bs-col-card-context">
        <div class="bs-col-card-area">
            <div class="bs-col-card-logo" :style="{ backgroundImage: `url(${CClogo})` }"></div>
            <div class="bs-col-card-info">
                <div class="bs-col-card-brand">{{CCtitle}}</div>
                <div class="bs-col-card-brandDesc">{{CCsubtitle}}</div>
            </div>
        </div>
        <div class="bs-col-card-desc">{{CCdescription}}</div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'CollaborationCard',
    data(){
        return{
            
        }
    },
    props: {
        CCtitle: {
            type: String,
            require: true
        },
        CCsubtitle: {
            type: String,
            require: true
        },
        CCdescription_code: {
            type: String,
            require: true
        },
        CClogo: {
            type: String,
            require: true
        },
        CCimage: {
            type: String,
            require: true
        }
    },
    computed: {
        CCdescription() {
            return this.$t(this.CCdescription_code);  // 키를 사용하여 번역된 문자열 반환
        }
    }
}
</script>

<style>
.bs-col-card-div {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    background-color: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
}
.bs-col-card-img {
    width: 100%;
    height: 344px;
    border-radius: 50px 50px 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bs-col-card-context {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
}
.bs-col-card-area {
    margin-bottom: 32px;
    text-align: left;
}
.bs-col-card-logo {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    border: thin solid rgba(0, 0, 0, 0.15);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: inline-block;
    vertical-align: middle;
}
.bs-col-card-info {
    margin-left: 16px;
    display: inline-block;
    vertical-align: middle;
}
.bs-col-card-brand {
    font-size: 30px;
    font-weight: 600;
}
.bs-col-card-brandDesc {
    font-size: 22px;
    font-weight: 500;
}
.bs-col-card-desc {
    font-size: 20px;
    font-family: 500;
    line-height: 165%;
    text-align: left;
}
</style>