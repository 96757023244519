<template>
  <div>
    <div class="cover-container d-flex justify-content-center flex-column">
      <p class="solutionSubtitle">{{ $t('business.solutionSection.subTitle') }}</p>
      <p class="solutionTitle">{{ $t('business.solutionSection.title') }}</p>
    </div>
    <div class="d-flex flex-column solAlign">
      <div class="container solContainer">
          <div class="solContainer_left">
              <img src="../../assets/image/solutions/solutionA.jpg" alt="Dynamic Head" class="solContents_width100 solContents_radius" />
          </div>
          <div class="solContainer_right">
            <div class="solContents_width90 solContents_textRight">
              <div>
                <p class="solContents_inline solContents_titleNumber">01&nbsp;</p>
                <p class="solContents_inline solContents_titleText">{{ $t('business.solutionSection.solA.title') }}</p>
              </div>
              <div>
                <p class="solContents_desc">{{ $t('business.solutionSection.solA.description.paragraphA.senA') }}</p>
                <p class="solContents_desc">{{ $t('business.solutionSection.solA.description.paragraphB.senA') }}</p>
              </div>
            </div>
          </div>
      </div>

      <div class="container solContainer">
        <div class="solContainer_left">
          <div class="solContents_width90 solContents_textLeft">
              <div>
                <p class="solContents_inline solContents_titleNumber ">02&nbsp;</p>
                <p class="solContents_inline solContents_titleText">{{ $t('business.solutionSection.solB.title') }}</p>
              </div>
              <div>
                <p class="solContents_desc">{{ $t('business.solutionSection.solB.description.paragraphA.senA') }}</p>
                <p class="solContents_desc">{{ $t('business.solutionSection.solB.description.paragraphB.senA') }}</p>
              </div>
          </div>
              
        </div>
          <div class="solContainer_right">
            <img src="../../assets/image/solutions/solutionB.jpg" alt="Dynamic Head" class="solContents_width100 solContents_radius" />
          </div>
      </div>

      <div class="container solContainer">
          <div class="solContainer_left">
            <img src="../../assets/image/solutions/solutionC.jpg" alt="Dynamic Head" class="solContents_width100 solContents_radius" />
          </div>
          <div class="solContainer_right">
            <div class="solContents_width90 solContents_textRight">
              <div>
                <p class="solContents_inline solContents_titleNumber">03&nbsp;</p>
                <p class="solContents_inline solContents_titleText">{{ $t('business.solutionSection.solC.title') }}</p>
              </div>
              <div>
                <p class="solContents_desc">{{ $t('business.solutionSection.solC.description.paragraphA.senA') }}</p>
                <p class="solContents_desc">{{ $t('business.solutionSection.solC.description.paragraphB.senA') }}</p>
              </div>
            </div>
          </div>
      </div>

      <div class="container solContainer" style="margin-bottom: 90px;">
        <div class="solContainer_left">
            <div class="solContents_width90 solContents_textLeft">
              <div>
                <p class="solContents_inline solContents_titleNumber">04&nbsp;</p>
                <p class="solContents_inline solContents_titleText">{{ $t('business.solutionSection.solD.title') }}</p>
              </div>
              <div>
                <p class="solContents_desc">{{ $t('business.solutionSection.solD.description.paragraphA.senA') }}</p>
                <p class="solContents_desc">{{ $t('business.solutionSection.solD.description.paragraphB.senA') }}</p>
              </div>
            </div>
          </div>
          <div class="solContainer_right">
            <img src="../../assets/image/solutions/solutionD.jpg" alt="Dynamic Head" class="solContents_width100 solContents_radius" />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Solutions',
  data(){
    return{
      
    }
  }
}
</script>

<style>
.container {
    display: flex;
}
.solutionSubtitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
}
.solutionTitle {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 60px;
}
.solContainer {
  margin-top: 90px;
}
.solAlign {
  align-items: center;
}
.solContainer_left {
  margin-right: 28px;
  text-align: right;
  align-content: center;
  width: 50%;
}
.solContainer_right {
  margin-left: 28px;
  text-align: left;
  align-content: center;
  width: 50%;
}
.solContents_width100 {
  width: 100%;
  border-radius: 50px;
}
.solContents_width90 {
  width: 90%;
}
.solContents_textRight {
  float: left;
}
.solContents_textLeft {
  float: right;
}
.solContents_inline {
  display: inline-block;
}
.solContents_titleNumber {
  font-size: 100px;
  font-weight: 700;
}
.solContents_titleText {
  font-size: 30px;
  font-weight: 900;
}
.solContents_desc {
  font-size: 20px;
  font-weight: 500;
}

@media screen and (max-width: 1000px) {
  .solContainer {
    margin-top: 72px;
  }
  .solContainer:nth-child(odd) {
    flex-direction: column-reverse;
  }
  
  .solContainer:nth-child(even) {
    flex-direction: column;
    /* text-align: left; */
  }
  .solContainer_left {
    width: 100%;
    margin: auto;
    text-align: left;
  }
  .solContainer_right {
    width: 100%;
    margin: auto;
  }
  .solContents_textLeft {
    float: left;
  }
  .solContents_width90 {
    all: unset;
  }
}
</style>