<template>
  <div>
    <div class="d-flex w-100 flex-column">
      <div class="container main_md_container">
        <main class="md_main">
          <img src="../../assets/image/logo/logo.svg" alt="" class="mdimg"/>
          <h1 class="mdh1">{{ $t('main.descriptionSection.title') }}</h1>
          <span class="mdsp mdsp1">{{ $t('main.descriptionSection.description.descriptionA') }}</span>
          <span class="mdsp">{{ $t('main.descriptionSection.description.descriptionB') }}</span>

          <ul class="mdUl">
            <li class="mdLi">
              <div class="mdLiDiv">
                <p class="mdLiTitle">Since</p>
                <p class="mdLiContext">2021</p>
              </div>
            </li>

            <li class="mdLi">
              <div class='mdline'></div>
            </li>

            <li class="mdLi">
              <div class="mdLiDiv">
                <p class="mdLiTitle">Engaging</p>
                <p class="mdLiContext">22M+</p>
              </div>
            </li>

            <li class="mdLi">
              <div class='mdline'></div>
            </li>

            <li class="mdLi">
              <div class="mdLiDiv">
                <p class="mdLiTitle">Community</p>
                <p class="mdLiContext">510K+</p>
              </div>
            </li>
          </ul>

        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainDescription',
  data(){
    return{
      
    }
  }
}
</script>

<style>
span{
  display: block;
}

.main_md_container {
  justify-content: center;
}
.md_main {
  text-align: -webkit-center;
}
.mdimg {
  max-width: 400px;
  filter: invert(14%) sepia(25%) saturate(3171%) hue-rotate(74deg) brightness(99%) contrast(102%);
}
.mdh1 {
  margin-top: 72px;
  font-size: 35px;
  font-weight: 700;
}
.mdsp1 {
  margin: 48px auto 0px; 
}
.mdsp {
  font-size: 20px;
  font-weight: 500;
  max-width: 960px;
}
.mdUl {
  margin-top: 64px;
  padding-left: 0;
}
.mdLi {
  vertical-align: middle;
}
.mdLiDiv {
  width: 250px;
  font-weight: 700;
}
.mdLiTitle {
  font-size: 22px;
}
.mdLiContext {
  font-size: 55px;
}
.mdline {
    border-left : thin solid #5f5f5f;
    height : 152px;
}

@media screen and (max-width: 1000px) {
  .mdh1 {
    max-width: 400px;
  }
  .mdsp {
    width: calc(100% - 160px);
  }
  .mdLiDiv {
    width: 200px;
  }
  .mdLiContext {
    font-size: 45px;
  }
  .mdline {
    height : 137px;
  }
}
@media screen and (max-width: 767px) {
  .mdimg {
    max-width: 360px;
  }
  .mdh1 {
    font-size: 30px;
    max-width: 360px;
  }
  .mdsp {
    width: calc(100% - 40px);
  }
  .mdLiDiv {
    width: 150px;
  }
  .mdLiTitle {
    font-size: 18px;
  }
  .mdLiContext {
    font-size: 40px;
  }
}
@media screen and (max-width: 490px) {
  .mdimg {
    max-width: 320px;
  }
  .mdLiDiv {
    width: 120px;
  }
  .mdLiTitle {
    font-size: 16px;
  }
  .mdLiContext {
    font-size: 32px;
  }
}
</style>