<template>
  <div>
    <div class="d-flex w-100 flex-column">
      <div class="container ab_brand_container">
        <main>
          <h1 class="brandingTitle">{{ $t('about.brandSection.title') }}</h1>
          <div class="brandingDesc">
            <span>{{ $t('about.brandSection.description.paragraphA.senA') }}</span>
            <span>{{ $t('about.brandSection.description.paragraphA.senB') }}</span>
          </div>

          <div class="brandingDesc brandingDesc_B">
            <span>{{ $t('about.brandSection.description.paragraphB.senA') }}</span>
            <span>{{ $t('about.brandSection.description.paragraphB.senB') }}</span>
          </div>

          <div class="brandingDesc">
            <span>{{ $t('about.brandSection.description.paragraphC.senA') }}</span>
            <span>{{ $t('about.brandSection.description.paragraphC.senB') }}</span>
          </div>
        </main>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Branding',
  data(){
    return{
      
    }
  }
}
</script>

<style>
.ab_brand_container {
  justify-content: center;
}



span{
  display: block;
}

.brandingTitle {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 70px;
}

.brandingDesc {
  font-size: 20px;
  font-weight: 500;
  
  max-width: 843px;
  margin: 30px auto;
}

.brandingDesc_B {
  font-weight: 800 !important;
}
</style>